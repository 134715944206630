<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="1" sm="1" md="1">
              <v-text-field v-model="editedItem.id" label="ID" disabled></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="model"
                :items="types_doc"
                label="Tipo de identificación"
                item-text="text"
                item-value="value"
                color="purple darken-2"
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field 
                v-model="editedItem.identification" 
                label="Identificación"
                :rules="rules.in_req"
                color="purple darken-2"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field 
                v-model="editedItem.name" 
                label="Nombres y Apellidos"
                :rules="rules.in_req"
                color="purple darken-2"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field 
                v-model="editedItem.address" 
                label="Dirección"
                :rules="rules.in_req"
                color="purple darken-2"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field 
                v-model="editedItem.email" 
                label="Correo"
                color="purple darken-2"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field 
                v-model="editedItem.phone" 
                type="number"
                :rules="rules.phoneRules"
                :counter="10"
                label="Celular"
                color="purple darken-2"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete 
                v-model="editedItem.genre"
                :items="genres"
                label="Genero"
                item-text="text"
                item-value="value"
                :rules="rules.in_req"
                color="purple darken-2"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-checkbox
								v-model="editedItem.advertising"
								label="Permite publicidad">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrarHijo">Cancelar</v-btn>
          <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

import { ApiPay  } from "./ApiPay";
import messagesMixin from "../../../mixins/messagesMixin"

export default {
  name: "NewClient",
  mixins:[messagesMixin],
  props: [
    'dialog',
    'editItem',
    'editedIdex'
  ],

  data() {
    return {
      api: new ApiPay(),
      loading: false,
      types_doc:[
        {text:"CEDULA", value:"CC"},
        {text:"TARJETA DE IDENTIDAD", value:"TI"},
        {text:"CEDULA DE EXTRANJERIA", value:"CE"},
        {text:"PASAPORTE", value:"PS"},
      ],
      model:this.editItem["type_identification"],
      editedItem:this.editItem,
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
        phoneRules: [val => (val || ''). length === 10 || 'Debe tener 10 numeros'],
        emailRules: [val => !!val || 'E-mail is required',
                        val => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val) || 'E-mail must be valid',
        ],
      }, 
      genres:[
          {text:"Masculino", value:"M"},
          {text:"Femenino", value:"F"}
        ],
    }
  },
  computed:{
		formTitle(){
			return (this.editedIdex === -1 ? "Nuevo": "Editar") + " Cliente"
		},
    formIsValid () {
      return (
        this.editedItem.identification &&
        this.editedItem.name &&
        this.editedItem.address &&
        this.editedItem.phone
      )
      },
    mostrar:{
      get(){
        return this.dialog
      },
      set(valor){
        return valor
      }
    }
  },
  methods: {
    async save(){
      const obj = this.editedItem

      if (this.editedIdex === -1){
        obj["type_identification"] = this.editedItem["type_identification"]["value"]
        obj["genre"] = this.editedItem["genre"]["value"]
      }

      obj["enterprise"] = localStorage.getItem("SucursalId")
      try {
        this.loading = true
        this.formIsValid = false
        var r = await this.api.saveClientes(obj)
        if (!r.ok){
          this.message(Object.values(r)[0][0],"Error","error")
        }
        else{
          this.message("Guardado Satisfactoriamente")
          this.cerrarHijo()
        }
        // this.iniciar()
      } catch (error) {
        // alert(error)
        this.message("Error guardando el cliente, por favor valide los datos","Error","error")
      } finally{
        this.loading = false
        this.formIsValid = true
      }
    },
    cerrarHijo(){
      this.$emit("cerrarDesdeHijo")
    },
  }
}
</script>

<style scoped>

</style>