<template>
  <div class="pl-14">
    <v-card
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      absolute
      dark
      scroll-target="#scrolling-techniques-6"
      elevate-on-scroll
    >
      <v-card-title>
        <h1 class="font-weight-bold display-6 color-texto">Facturación {{sucursal}}</h1>
      </v-card-title>
    </v-card>
      <v-tabs 
        grow
        dark
      >
          <v-tab>Facturación</v-tab>
          <v-tab>Clientes</v-tab>
          <v-tab>Facturas</v-tab>
          <v-tab>Métodos de pago</v-tab>
          <v-tab-item><br><Fact /></v-tab-item>
          <v-tab-item><br><Clientes /></v-tab-item>
          <v-tab-item><br><ListBills /></v-tab-item>
          <v-tab-item><br><PayMethods /></v-tab-item>
      </v-tabs>
    <v-sheet id="scrolling-techniques-6" class="overflow-y-auto" max-height="600">
      <v-container style="height: 60px;"></v-container>
    </v-sheet>
  </div>
</template>

<script>

import Clientes from "./Clients"
import Fact from "./Billing"
import ListBills from "./ListBills";
import PayMethods from "./PayMethods";


export default {

  name: "Facturacion",
  components: {
    Clientes,
    Fact,
		ListBills,
    PayMethods,
  },
  data() {
    return {
        opt: null,
        collapseOnScroll: true,
        sucursal: localStorage.getItem("Sucursal")
    };
  },
  methods: {
    chooseOpt(opt){
      this.opt = opt
    }
  }
};
</script>

<style scoped>
.color-texto {
    color: #356859 !important;
}
</style>
