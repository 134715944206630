<template>
	<v-data-table
	:headers="headers"
	:items="items"
	:single-expand="true"
	:expanded.sync="expanded"
	:search="search"
	:loading="loading"
	:page.sync="page"
	:items-per-page="pages"
	hide-default-footer
	item-key="id"
	show-expand
	class="elevation-1"
	loading-text="Cargando..."
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Facturas</v-toolbar-title>
				<v-divider class="mx-4" inset vertical></v-divider>
				<v-text-field 
					v-model="search" 
					append-icon="mdi-magnify" 
					label="Buscar"
				>
				</v-text-field>
				<v-col cols="6">
					<v-container class="max-width">
						<v-pagination
							v-model="page"
							:length="pageCount"
							circle
						></v-pagination>
					</v-container>
				</v-col>
				<v-spacer></v-spacer>
				<v-btn color="warning" dark class="mb-2" @click="init">
					<v-icon>cached</v-icon>
				</v-btn>
			</v-toolbar>
		</template>
		<template v-slot:no-data>
			Listado facturas
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td :colspan="headers.length">
				<v-data-table
					dense
					:headers="itemsHeaders"
					:items="item.items"
					class="elevation-1"
				>
					<template v-slot:item.service_desc="{ item }">
						<div v-if="item.product_desc">
							{{item.product_desc}}
						</div>
						<div else>
							{{item.service_desc}}
						</div>
					</template>
				</v-data-table>
			</td>
		</template>
	</v-data-table>
</template>

<script>

import { ApiPay  } from "./ApiPay";

export default {

	name: "ListBills",

	data() {
		return{
			api: new ApiPay(),
			loading:false,
			items:[],
			page:1,
			pageCount: 0,
			totalBills:0,
			search:"",
			expanded: [],
			singleExpand: false,
			pages:-1,
			headers: [
				{
					text: 'N. Factura',
					value: 'id',
				},
				{ text: 'Fecha', value: 'bill_date' },
				{ text: 'Cliente', value: 'client_desc' },
				{ text: 'Puntos Canjeados', value: 'points_exchange' },
				{ text: 'Metodo de Pago', value: 'pay_method_desc' },
				{ text: 'Total', value: 'total' },
			],
			itemsHeaders: [
				{ text: 'item', value: 'item'},
				{ text: 'Producto/Servicio', value:"service_desc"},
				{ text: 'Cantidad', value:"quantity"},
				{ text: 'Comision', value: 'comission_pay' },
				{ text: 'Precio servicio', value: 'service_price' },
				{ text: 'Prestador', value: 'employee_desc' },
			]
		}
	},
	watch:{
		page(val) {
			this.init(val)
		},
	},
	methods:{
		async init(){
			try {
				this.loading = true;
				let params = `enterprise=${localStorage.getItem("SucursalId")}&page=${this.page}`
				let r = await this.api.getFacturasPaginator(params);
				this.items = r.results; 
				this.totalBills = r.count; 
				this.pages = this.items.length
				if (this.page === 1){
					this.pageCount = Math.ceil(this.totalBills / this.pages)
				}
			} catch (error) {
				alert(error);
				// this.mensaje(error,"Error","error")
				// this.msgError(error)
				this.$swal("Error", error.toString())
			} finally {
				this.loading = false;
			}
		},
	},
	created(){
		this.init()
	},
}
</script>

<style scoped>

</style>